import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Header from "../components/Header/header"
import * as styles from "../styles/about.module.css"
import Footer from "../components/Footer/footer"

const About = () => (
    <React.Fragment>
        <Helmet title="Silver Oaks - About" />

        <Header />

        <div>
            <StaticImage
                src="../images/logs.jpg"
                alt="Timber forest"
                placeholder="blurred"
                layout="fullWidth"
                className={styles.imgContainer}
            />
            <div className={styles.imgTextContainer}>
                <h1 className={styles.imgText}>About Us</h1>
            </div>
        </div>

        <div
            className={styles.splitContainer}
            style={{ paddingTop: "6.6vmin", paddingBottom: "3vmin" }}
        >
            <div className={styles.splitContainerContent}>
                <div
                    className={styles.textContainer}
                    style={{ marginRight: 30 }}
                >
                    <h1>New Zealand Timber Exporters</h1>
                    <p>
                        Silver Oaks International Ltd. is a trading company
                        involved in export of timber. Our key intention is to
                        provide our customers with efficient and reliable
                        solutions for all their timber requirements. We are
                        always poised to provide professional and reliable to
                        variety of industries to meet their timber requirements.
                        <br />
                        <br />
                        Through hard work, passion, focus, perceptiveness and
                        continual commitment, we aim to ensure all our
                        stakeholders work effortlessly to drive excellence
                        throughout all aspects of our business.
                        <br />
                        <br />
                        The company has developed strong supply chains and
                        financial partnerships to minimize risks and costs of
                        international trade for all parties involved, which lead
                        to more and more cooperation across various industries.
                    </p>
                </div>
                <div
                    className={styles.rightContainer}
                    style={{ marginLeft: 30 }}
                >
                    <h1>Our Values</h1>

                    <ul>
                        <li>
                            Honesty, integrity and respect are the key in all
                            our relationships
                        </li>
                        <li>
                            We are easy to deal with for suppliers and customers
                        </li>
                        <li>We make the difference through attitude</li>
                        <li>
                            We believe in relationships and have a long term
                            view to business
                        </li>
                        <li>
                            We use all our expertise and leverage to make our
                            customers and suppliers successful not only today,
                            but for the coming years
                        </li>
                        <li>We do what we say we do</li>
                        <li>We have fun</li>
                    </ul>
                </div>
            </div>
        </div>

        <Footer />
    </React.Fragment>
)

export default About
