// extracted by mini-css-extract-plugin
export var imgContainer = "about-module--imgContainer--xf7u9";
export var imgTextContainer = "about-module--imgTextContainer--24409";
export var imgText = "about-module--imgText--2TFVa";
export var bylineContainer = "about-module--bylineContainer--1dbQS";
export var splitContainer = "about-module--splitContainer--kMHQN";
export var splitContainerContent = "about-module--splitContainerContent--26ajj";
export var textContainer = "about-module--textContainer--1tDDq";
export var rightContainer = "about-module--rightContainer--3v-PI";
export var bylineContent = "about-module--bylineContent--V-B42";
export var leftByline = "about-module--leftByline--2b8Nx";
export var rightByline = "about-module--rightByline--An2Ha";